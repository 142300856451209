/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeE from "../../templates/PageTypeE"

import D365SupplyChainImg from "../../images/D365-supplychainmanagement.png"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Supply Chain Management"
      description="Supply Chain Management helps you streamline operations and improve your relationships with your supply chain partners and customers."
      linkSubPageGroupID="D365Group"
      titleImg={D365SupplyChainImg}
      infoList={[
        {
          title: "Streamline Operations",
          text: `Supply Chain Management helps you streamline operations and improve your relationships with your supply chain partners and customers. Plan and re-organize production processes to get ready to face four key challenges: business success depends on streamlined distribution, satisfied customers, productive relationships with partners and suppliers, and rapid time to market.`,
        },
        {
          title: "Streamline Distribution & Help Reduce Costs",
          text: `The warehouse management and distribution functionality in Microsoft Dynamics Business Central can help you run your warehousing operations more cost effectively. Instant access to accurate warehouse and inventory data increases efficiency and helps your people respond more quickly to customer needs. With a more efficient organization, you can reduce costs, offer your customers more competitive prices, and build customer loyalty.`,
        },
        {
          title: "Manage Inventory Across Multiple Locations",
          text: `Store your inventory items for all locations-warehouses, service cars, and plants-in one database, facilitating a complete, real-time overview of your business. All items can be treated as stock keeping units (SKUs). This means identical items with the same item number can be stored at different locations and managed separately from each location.`,
        },
        {
          title: "Optimize Warehouse Operations",
          text: `Directed picking helps warehouse personnel locate and pick inventory. Directed put-away helps personnel determine where and how inventory should be placed in the warehouse. And with cross docking, your employees can identify what incoming purchases need to be shipped out right away, helping to streamline the handling process.`,
        },
        {
          title: "Reduce Warehouse Management Costs",
          text: `Microsoft Dynamics solutions for financial management can help you promote the kind of business growth you want to bring about. Business and technical expertise from Microsoft and its many partners can assist you in gaining the best value from your technology as your requirements change.`,
        },
        {
          title: "Increase Inventory Accuracy",
          text: `Cycle Counting helps you track the counting frequency per item or SKU, increasing accuracy and helping you keep your promises to customers to ship on time.`,
        },
        {
          title: "Strengthen and Develop Customer Relationships",
          text: `Microsoft Dynamics Business Central helps you improve customer relationships and win customer loyalty by enabling your people to fulfill orders more efficiently. When customers know you can consistently deliver the items they want, they’re more likely to come back.`,
        },
        {
          title: "Track Item Numbers Efficiently",
          text: `Vendors, distributors, and customers often identify the same item in different ways. As part of good customer service, companies must be able to cross-reference their customers’ internal item numbers. Microsoft Dynamics Business Central enables cross-referencing that helps you reference any of the several numbers for an item, and then find that item instantly.`,
        },
        {
          title: "Substitute Inventory Items",
          text: `Avoid losing customers because of stock shortages. Detailed views of your inventory and your suppliers’ capabilities can help you track and offer alternative items with similar characteristics quickly.`,
        },
        {
          title: "Manage Returns Effectively",
          text: `Process customer returns from a single window where you access and enter all information related to that return. Assign credit memos, order replacement items, and ship damaged items back to suppliers-all from the same screen`,
        },
        {
          title: "Fine Tune Order Promising",
          text: `Help your salespeople create sales orders. Order Promising calculates the earliest possible date that an item on a sales order line can be shipped and delivered. Whether the order is available to promise (ATP) or capable to promise (CTP), you can calculate shipping, picking, and delivery dates`,
        },
        {
          title: "Improve Relationships with Your Suppliers and Partners",
          text: `Uncover inefficiencies in relationships with partners and suppliers and learn how to make business interactions productive. Build on relationships with partners and suppliers to create a more profitable and competitive business model.`,
        },
        {
          title: "Streamline Order Fulfillment",
          text: `Promote automation and self-service. Commerce Gateway opens Microsoft Dynamics Business Central for the electronic exchange of trading documents with other systems. Commerce Gateway helps reduce errors and the amount of time spent entering data for items such as purchases and sales orders.`,
        },
        {
          title: "Connect to Partners Online",
          text: `Connect information and processes with security-enhanced options. No matter where you’re located, you can exchange information about design drawings, production schedules, and item specifications. Increase collaboration between employees, suppliers, and partners to help them create new, innovative offerings and enhance existing ones.`,
        },
        {
          title: "Alert Your Team to Changes",
          text: `Notify your team when you change key information. User-specific alerts and notifications help to ensure that all team members are kept up to date.`,
        },
        {
          title: "Access Critical Information",
          text: `Provide your employees with access to critical business information. Employee Portal helps increase productivity and improve decision-making communications across the organization.`,
        },
        {
          title: "Get Your Products to Market Faster",
          text: `Today’s supply chain leaders are characterized by their ability to deliver the products customers want with minimum time-to-market and maximum ability to revamp products to meet market expectations. Flexible planning capabilities can give you the freedom to choose the best manufacturing process to maximize profitability.`,
        },
        {
          title: "Make Changes Quickly",
          text: `Alter capacity plans and manufacturing policies, and implement the best manufacturing process to accommodate changing demands and new projects. You can also change the method by which your warehouse operates by quickly switching from standard pick method to wave picks, for example.`,
        },
        {
          title: "Improve Service Item Tracking",
          text: `Service management functionality helps you register all your service items and parts, access service level agreement information, and track all components of a service item and view their status.`,
        },
        {
          title: "Make More Informed Decisions",
          text: `Customizable reports, graphical key performance indicators, and rich drill-down capabilities help provide your people with instant access to the information they need to do their jobs. With easy-to-use tools for Online Analytical Processing (OLAP), you can extract, analyze, and present data from any angle across your entire business. And the Microsoft Office Outlook messaging and collaboration client style of interface is familiar and easy to use.`,
        },
      ]}
    />
  )
}
